@import 'theme';
@import '../beneficiaries/fileUploadPages/file-uploader-stepper/StepperRoot.scss';

$positiveColor: #00a878;
$negativeColor: #ff5252;
$attentionColor: #ffc000;

$globalConfig: ();

@mixin globalConfigMixin($config) {
  @include StepperRoot($config);
}

@include globalConfigMixin($globalConfig);

.popup-window {
  top: 50%;
  left: 0;
  z-index: 2000;
  right: 0;
  max-width: 600px;
  margin: 0 auto;
  transform: translateY(-50%);
  max-height: 400px;
  height: 100%;
  width: 100%;
}

.modal-large {
  max-width: 70%;
}

.modal-medium {
  max-width: 900px;
}

.navbar-brand {
  height: 105px;

  img {
    height: 100%;
  }
}

.icon-help-dark {
  cursor: pointer;
}

.av-loading-bullet {
  color: $primaryColor !important;
}

.loading {
  .rc-switch,
  .rc-switch:after {
    transition: none !important;
  }
}

//theme
.wfp-btn {
  color: $primaryButton;
  border-color: $primaryButton;
}

.wfp-btn--primary {
  background-color: $primaryButton;
  border-color: $primaryButton;
}

.wfp-btn:hover {
  background-color: $primaryButton;
  border-color: $primaryButton;
}

.wfp-btn--primary:hover {
  background-color: $primaryButtonHover;
  border-color: $primaryButtonHover;
}

.wfp-btn.active {
  background-color: $primaryButtonActive;
  border-color: $primaryButtonActive;

  &:hover {
    background-color: $primaryButtonActive;
    border-color: $primaryButtonActive;
  }

  &:active {
    background-color: $primaryButtonActive;
    border-color: $primaryButtonActive;
  }

  &:focus {
    background-color: $primaryButtonActive;
    border-color: $primaryButtonActive;
  }
}

// global
a,
.cursor-pointer {
  cursor: pointer;
}

.replaced-input-file {
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.filters-form {
  $checkbox-width: 24px;

  input[type='checkbox'] {
    display: inline-block;
    width: $checkbox-width;
  }

  input[type='text'],
  input[type='number'],
  .replaced-form-input {
    display: inline-block;
    width: calc(100% - 0.25em - #{$checkbox-width});
  }

  select,
  .row {
    select {
      display: inline-block;
      width: calc(100% - 0.25em - #{$checkbox-width});
    }
  }

  .rdrMonthPicker,
  .rdrYearPicker {
    display: inline-block;
    width: calc(100% - 0.25em - #{$checkbox-width});

    select {
      width: 100%;
    }
  }

  .rdrDefinedRangesWrapper {
    .rdrInputRanges {
      .rdrInputRange {
        .rdrInputRangeInput {
          width: 35%;
        }

        span {
          margin: 5px;
        }
      }
    }
  }
}

.dateFilterWithApply {
  border: 2px solid #999999;

  .rdrDateRangePickerWrapper {
    border-bottom: 2px solid #999999;
  }
}

.rdrInputRanges {
  visibility: hidden;
}

// layout
.wfp-header-ext {
  overflow: hidden;
  background-color: $primaryColor;
  max-height: none;

  .nav > li > a {
    color: #fff;

    &:hover,
    &:focus {
      background-color: $primaryColor;
    }
  }
}

.wfp-form--stacked {
  .invalid {
    margin-bottom: 0.25em !important;
  }
}

.entitlement {
  padding: 5px;
  border-bottom: 1px dashed lightblue;

  &:last-of-type {
    border-bottom: none;
  }

  input.month {
    width: 70px;
  }

  input.year {
    width: 100px;
  }

  input.day {
    width: 70px;
  }

  input.amount {
    width: 80px;
  }

  select.form-control {
    background-image: none;
  }

  label {
    min-width: 70px;
  }
}

.wfp-form--stacked label {
  font-weight: normal;
}

.wfp-notice {
  font-weight: lighter;
  font-size: 10px;
}

.wfp-table--striped {
  tr {
    &.failed td {
      background-color: #e08282;
    }
  }
}

table tbody tr.highlighted td {
  background-color: lightyellow !important;
}

.wfp-table--striped {
  tr {
    &.pending-action td {
      background-color: #e0c636;
    }
  }
}

.wfp-table--striped {
  tr {
    &.unknown td {
      background-color: rgba(151, 149, 149, 0.77);
    }
  }
}

.alert-button {
  float: right;
  font-weight: bold;
  margin-right: 10px;
  color: #000;
  filter: alpha(opacity=20);
  opacity: 0.6;
}

.negative-color {
  color: $negativeColor;
}

.attention-color {
  color: $attentionColor;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.indicator {
  text-transform: uppercase;
  font-size: 11px;
  vertical-align: 6px;
  padding: 5px;
  border-radius: 14px;
  margin-left: 7px;
}

.blocked-indicator {
  @extend .indicator;
  background-color: #ff0000;
  color: #ffffff;
}

.active-indicator {
  @extend .indicator;
  background-color: #177b57;
  color: #ffffff;
}

textarea,
input,
button {
  outline: none;
}

.wfp-menu .wfp-btn.active {
  outline: none;
  text-decoration: none;
}

.container-live {
  font-size: 11pt;
}

.container-live table td {
  text-align: center;
}

.container-live table th {
  text-align: center;
}

.shadowed-container {
  border-radius: 3px;
  box-shadow: 0 3px 5px #bababa;
  border-color: #bababa;
  background: #f9fcff;
}

.wfp-account-table {
  td,
  th {
    &:first-of-type {
      white-space: nowrap;
      text-align: left;
    }

    text-align: right;
  }

  .total-balance {
    font-weight: bold;
  }
}

.wfp-caseload-table {
  table-layout: fixed;

  td, th {
    overflow-wrap: break-word !important;
    word-break: break-all !important;
  }

  th:first-child, td:first-child {
    width: 50% !important;
    text-align: left;
  }
  th:nth-child(2), td:nth-child(2) {
    width: 20% !important;
    text-align: right;
  }
  th:last-child, td:last-child {
    width: 30% !important;
    text-align: right;
  }
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-self: center;

  > li {
    white-space: nowrap;
    // flex-basis: 16.66%;
    &:hover {
      border-bottom: 1px solid lightblue;
    }

    float: right;
    height: 50%;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    margin: 0 !important;
    padding: 0;

    a {
      border: none;
    }

    &.dropdown {
      position: relative;

      .dropdown-menu {
        position: fixed;
        right: inherit;
        top: inherit;
        // z-index: 10;
      }
    }
  }
}

.async-tasks-table {
  td {
    text-align: center;
  }

  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 10px;
      }
    }

    tr.parent {
      td {
        font-size: 16px;
      }

      &:hover {
        background-color: #85c1fd;
      }

      border: 1px solid rgba(0, 0, 0, .3);
      // &:nth-child(2n-1) {
      background-color: #b2d8fe;
      // }
      // &:nth-child(2n) {
      //   background-color: #85c1fd;
      // }
    }

    tr.child {
      border: 1px solid rgba(0, 0, 0, .3);

      &:hover {
        background-color: #e8e8e8;
      }

      background-color: white;

      td {
        word-wrap: break-word;

        &:first-child {
          padding-left: 40px;
          text-align: right;
        }

        font-size: 14px;
      }
    }
  }
}

.beneficiary-component-padding {
  padding: 25px;
}

.beneficiary-alert-component {
  @extend .beneficiary-component-padding;
}

.beneficiary-totals-component {
  @extend .beneficiary-component-padding;
}

.beneficiary-alert-debits-component {
  padding-bottom: 25px;
}

.modal-label {
  text-align: left;
  font-size: 80%;
}

.myNavbarItem {
  margin: 10px;
}

a:not([href]):not([tabindex]) {
  color: #036fdc;
}

label {
  font-weight: bold;
}

.BB-two-column-layout {
  column-count: 2;
}

@media (max-width: 800px) {
  .BB-two-column-layout {
    column-count: 1;
  }
}

.BDS-documents-page-header {
  display: grid;
  grid-template-columns: 1fr auto;

  .header-container {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    gap: 1rem;
  }

  a {
    border-bottom: none;
  }
}

.BDS-upload-documents-form-container {
  display: grid;
  gap: 1rem;

  button {
    justify-self: center;
  }

  label {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 8fr;
    gap: 0 15px;

    input {
      width: 100%;
    }

    span {
      text-align: right;
    }
  }

  .progress {
    width: 50%;
    justify-self: center;

    [role='progressbar'] {
      width: 100%;
    }
  }
}

.BDS-document-section-wrapper {
  &.active {
    & > * {
      pointer-events: none;
    }
  }

  svg {
    opacity: 0;
  }

  &:hover {
    h5 svg {
      opacity: 1;
    }
  }

  .BDS-document-list-item-wrapper {
    position: relative;
    display: inline-grid;
    grid-auto-flow: column;
    justify-content: start;
    gap: 0.5rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      background: red;
      align-self: center;
      border-radius: 100%;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }

    & > button {
      all: unset;
      display: flex;
      align-items: center;
    }
  }

  button {
    svg {
      width: 1.25rem;
      fill: lighten($primaryColor, 25%);
      will-change: fill;
      transition: fill 130ms ease-in;

      &:hover {
        fill: $primaryColor;
      }
    }
  }

  & > h5 {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    button {
      all: unset;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 1.25rem;
        fill: lighten($primaryColor, 25%);
        will-change: fill;
        transition: fill 130ms ease-in;

        &:hover {
          fill: $primaryColor;
        }
      }
    }
  }
}

.BB-column-wrapper {
  display: flex;
}

.BB-select {
  border: 1px solid tomato;
  width: 100%;
}

.wfp-wrapper.BB-wrapper--wide {
  width: 100vw;
}

@media screen and (min-width: 80em) {
  .wfp-wrapper.BB-wrapper--wide {
    max-width: 100vw;
  }
}

.react-select-container {
  input[type='text'] {
    box-shadow: unset;
  }
}

.statistic-loader {
  animation: blinker 1.5s linear infinite;
  opacity: 0.9;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.logo-container {
  width: 180px;
  margin: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.home-page {
  article p {
    margin-top: 1.5rem;
  }
}
